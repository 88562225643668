import { Badge, Grid, GridItem, Image } from '@pitchero/react-ui';
import { Trans } from 'lib/i18n';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import { find, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getCurrentClub } from 'store/clubs/selectors';
import Cell from './cell';

const Table = ({ columns, rows, club, isMobile }) => {
  if (rows.length === 0) {
    return null;
  }

  const populatedRows = rows.filter((row) => row.column_values.length > 0);
  if (populatedRows.length === 0) {
    return null;
  }

  let pointsColumn = get(find(columns, { type: 'points' }), 'key');
  if (!pointsColumn) {
    pointsColumn = get(
      find(columns, (column) => column.key !== 'team_name'),
      'key',
      'pts',
    );
  }

  const filteredColumns = columns.filter(
    (column) => column.key !== 'team_name' && column.key !== pointsColumn,
  );

  const extraColsGridSize =
    filteredColumns.length > 0
      ? `repeat(${filteredColumns.length}, ${isMobile ? '30px' : '40px'})`
      : '';
  const gridTemplateCols = isMobile
    ? `30px 0 minmax(0,1fr) ${extraColsGridSize} 30px`
    : `60px 40px minmax(0,1fr) ${extraColsGridSize} 60px`;

  return (
    <>
      <Grid
        columns={gridTemplateCols}
        columnGap="2px"
        style={{
          fontSize: '14px',
          backgroundColor: '#EEE',
          gridAutoRows: isMobile ? '40px' : '60px',
          paddingRight: isMobile ? '5px' : '0',
        }}
      >
        <Cell weight="bold">#</Cell>
        <GridItem
          columnStart={2}
          columnEnd={4}
          justifySelf="start"
          alignSelf="center"
          component="div"
        >
          <Cell weight="bold">
            <Trans i18nKey="teams:table_team_col_header">Team</Trans>
          </Cell>
        </GridItem>
        {filteredColumns.map((col) => (
          <Cell weight="bold" key={col.key}>
            {col.short_label}
          </Cell>
        ))}
        <Cell weight="bold">Pts</Cell>
      </Grid>
      {rows.map((row, index) => {
        const name = find(row.column_values, { key: 'team_name' });
        const points = get(
          find(
            row.column_values,
            (value) => value.key.toLowerCase() === pointsColumn.toLowerCase(),
          ),
          'value',
        );

        // Show a light solid border under the row unless it's the last one
        // Use a darker dashed border if the row is marked as having a divide after
        let borderBottom = '';
        const showBorder = index < rows.length - 1;
        if (showBorder) {
          borderBottom =
            row.horizontal_divide === 'after' ? '1px dashed #999' : '1px solid #f0f0f0';
        }
        return (
          <Grid
            key={row.rank}
            columns={gridTemplateCols}
            columnGap="2px"
            style={{
              fontSize: '14px',
              backgroundColor: row.is_highlighted ? '#ebf5ff' : 'transparent',
              gridAutoRows: isMobile ? '40px' : '60px',
              paddingRight: isMobile ? '5px' : '0',
              borderBottom,
            }}
          >
            <Cell>{row.rank}</Cell>
            <Cell>
              {!isMobile &&
                (club.logo && row.is_highlighted ? (
                  <Image
                    src={club.logo}
                    alt={club.name}
                    height={32}
                    width={32}
                    transformation="square"
                    style={{ display: 'flex' }}
                    loading="lazy"
                  />
                ) : (
                  <Badge
                    primary={row.primary_colour}
                    secondary={row.secondary_colour}
                    size={24}
                    style={{ verticalAlign: 'middle' }}
                  />
                ))}
            </Cell>
            <Cell
              justify="start"
              weight="bold"
              textStyle={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {name.value}
            </Cell>
            {filteredColumns.map((column) => {
              const rowCol = find(row.column_values, { key: column.key });
              return <Cell key={column.key}>{rowCol ? rowCol.value : 0}</Cell>;
            })}
            <Cell weight="bold">{points}</Cell>
          </Grid>
        );
      })}
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      show_in_condensed: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      is_highlighted: PropTypes.bool.isRequired,
      primary_colour: PropTypes.string.isRequired,
      secondary_colour: PropTypes.string.isRequired,
      column_values: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
        }),
      ),
    }),
  ).isRequired,
  club: CLUB_PROP_TYPES.isRequired,
  isMobile: PropTypes.bool,
};

Table.defaultProps = {
  isMobile: false,
};

const mapStateToProps = (state) => ({
  club: getCurrentClub(state),
});

export default connect(mapStateToProps)(Table);
